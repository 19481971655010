import React, {useContext, useEffect, useState} from 'react';
import {graphql, Link} from 'gatsby';
import {RichText} from 'prismic-reactjs';
import Layout from '@components/layout';
import EdmusContext from '@components/application.provider';
import SEO from '@components/seo';
import Video from '@components/video';
import {PrismicVideo, PrismicImage} from 'src/models/Prismic.types';
import {navigate} from '@reach/router';
import useLabels from '@hooks/useLabels';
import MenuSlider from '@components/menu-slider';
import useDialog from '@hooks/useDialog';
export const query = graphql`
  query PageQuery($uid: String) {
    prismic {
      allVintages: allVintages(uid: $uid) {
        edges {
          node {
            _meta {
              lang
              uid
            }
            title
            description
            year
            isOrderable
            denomination
            quote
            video
            videoImage
            imageWineBottle
            technicalSheet {
              ... on PRISMIC__FileLink {
                _linkType
                url
              }
            }
            pressKit {
              ... on PRISMIC__FileLink {
                _linkType
                url
              }
            }
          }
        }
      }
      vintages: allVintages(first: 30) {
        edges {
          node {
            _meta {
              lang
              uid
            }
            year
            isOrderable
          }
        }
      }
    }
  }
`;

const Vintage = (props: any) => {
  const {labels} = useLabels();
  const {language, openDialog} = useContext(EdmusContext);
  const [doc, setDoc] = useState<VintageType>();
  const [select, setSelect] = useState<string>();

  useEffect(() => {
    const doc: VintageType = props.data.prismic.allVintages.edges.find(
      (vintage: VintageType) => vintage.node._meta.lang.slice(0, 2) === language
    );
    setDoc(doc);
    setSelect(doc.node._meta.uid);
  }, []);

  const vintagesList: LightVintageType[] = props.data.prismic.vintages.edges
    .filter(
      (vintage: VintageListType) =>
        vintage.node._meta.lang.slice(0, 2) === language
    )
    .map((vintage: VintageListType) => {
      return {
        year: vintage.node.year,
        id: vintage.node._meta.uid,
        isOrderable: vintage.node.isOrderable,
      };
    })
    .sort((a: LightVintageType, b: LightVintageType) => {
      if (a.year > b.year) {
        return 1;
      }
      if (a.year < b.year) {
        return -1;
      }
      return 0;
    });

  useEffect(() => {
    if (doc && doc.node._meta.lang.slice(0, 2) !== language)
      navigate(`${language}-${doc.node.year}`);
  }, [language]);

  useEffect(() => {
    if (doc && select !== doc.node._meta.uid) navigate(`${select}`);
  }, [select]);

  if (!doc) return null;

  return (
    <Layout>
      <div className='vintage'>
        <SEO title={doc.node.title} />

        <div className='container sub-container'>
          <div className='hide-sm'>
            <MenuSlider vintagelist={vintagesList} year={doc.node.year} />
          </div>
        </div>
        <div className='select-vintage show-sm'>
          <div className='form-group wine-title'>
            <select
              className='form-select '
              value={select}
              onChange={e => setSelect(e.target.value)}
            >
              {vintagesList &&
                vintagesList.map((vintage, key) => (
                  <option
                    value={vintage.id}
                    className={vintage.year === doc.node.year ? 'active' : ''}
                    key={key}
                  >
                    {labels.VINTAGE} {vintage.year}
                    {vintage.isOrderable && ' - commander'}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className='p-relative'>
          <div className='wine-block'>
            <div className=''>
              <div className='wine-title'>
                <div className='small-title space-bottom-5'>{labels.WINE}</div>
                {doc.node.denomination && (
                  <div className='title'>
                    {RichText.render(doc.node.denomination)}
                  </div>
                )}
                {doc.node.description && (
                  <div>{RichText.render(doc.node.description)}</div>
                )}
                {doc.node.isOrderable === true && (
                  <div className='space-top-20'>
                    <a
                      onClick={e => {
                        e.preventDefault();
                        openDialog();
                      }}
                      href='/stores'
                      className='order-btn'
                    >
                      {labels.VINTAGE_ORDER}
                    </a>
                  </div>
                )}
              </div>

              <div className='show-sm  img-container'>
                {doc.node.imageWineBottle && (
                  <img
                    className=' bottle-image lazyloading'
                    src={doc.node.imageWineBottle.url}
                  />
                )}
              </div>

              <div className='wine-quote'>
                <div className='align-vertically'>
                  <div className='text-center quote'>
                    <i className='text-center icon-quote' />
                  </div>
                  {doc.node.quote && (
                    <div className='text-center'>
                      {RichText.render(doc.node.quote)}
                    </div>
                  )}
                </div>
              </div>
              {doc.node.imageWineBottle && (
                <img
                  className='hide-sm bottle-image'
                  src={doc.node.imageWineBottle.url}
                />
              )}
            </div>
          </div>

          {doc.node.video && (
            <Video video={doc.node.video} image={doc.node.videoImage} />
          )}

          <div className='columns space-top-20 space-bottom-20'>
            {doc.node.technicalSheet && (
              <div className='col-6 text-right inner-space-right-10'>
                <a
                  className='edmus-btn'
                  download
                  href={doc.node.technicalSheet.url}
                >
                  {labels.VINTAGE_TECHNIC_SHEET}
                </a>
              </div>
            )}
            {doc.node.pressKit && (
              <div className='col-6 text-left inner-space-left-10'>
                <a className='edmus-btn' download href={doc.node.pressKit.url}>
                  {labels.VINTAGE_PRESS_KIT}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Vintage;

type VintageType = {
  node: {
    _meta: {
      lang: string;
      uid: string;
    };
    title: string;
    year: string;
    isOrderable: boolean;
    denomination: any;
    description: any;
    quote: any;
    video: PrismicVideo;
    videoImage: PrismicImage;
    imageWineBottle: PrismicImage;
    technicalSheet: {
      url: string;
    };
    pressKit: {
      url: string;
    };
  };
};

type VintageListType = {
  node: {
    _meta: {
      lang: string;
      uid: string;
    };
    isOrderable: boolean;
    year: string;
  };
};

type LightVintageType = {year: string; id: string; isOrderable: boolean};
