
import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";

type LightVintageType = { year: string, id: string, isOrderable: boolean };

const MenuSlider = (props: { vintagelist: LightVintageType[], year: string }) => {

    const slider = useRef<HTMLInputElement>(null);
    const [displayButton, setDisplayButton] = useState<boolean>(false);

    function buttonLeftAction(offset: number) {
        if (slider && slider.current) {
            slider.current.scrollLeft = slider.current.scrollLeft - offset;
        }
    }

    function buttonRightAction(offset: number) {
        if (slider && slider.current) {
            slider.current.scrollLeft = slider.current.scrollLeft + offset;
        }
    }

    useEffect(() => { // Scroll to selected year on page loaded. 

        let itemSize = 115; // Size of each item (width + padding + margin)

        let offset = 0;
        let scrollLeft = 0;

        if (slider && slider.current) {
            offset += itemSize;
            slider.current.classList.remove('smooth');
            for (let item of props.vintagelist) {
                if (item.year === props.year && scrollLeft === 0) {
                    scrollLeft = offset - (slider.current.clientWidth / 2);
                }
                offset += itemSize;
            }

            slider.current.scrollLeft = scrollLeft;
            slider.current.classList.add('smooth');

            (window.onresize = function onResize() {
                if (offset > window.innerWidth) {
                    setDisplayButton(true);
                } else {
                    setDisplayButton(false);
                }
            })();

        }

    }, [])

    return (
        <div className="menu-slider-wrapper">

            {displayButton &&
                <div className="menu-slider-button left" onClick={() => buttonLeftAction(220)}>
                    {"\<"}
                </div>
            }
            <div
                className="menu-slider"
                ref={slider}>
                {props.vintagelist.map((vintage, index) => {
                    return (
                        <Link
                            key={"vintage-" + index}
                            className='element'
                            to={'/vintage/' + vintage.id}
                            activeClassName='active'>

                            {vintage.year}
                            {vintage.isOrderable &&
                                <i className='icon-shop' />
                            }
                        </Link>
                    )
                })}
            </div>

            {displayButton &&
                <div className="menu-slider-button right" onClick={() => buttonRightAction(220)}>
                    {"\>"}
                </div>
            }
            
        </div>
    )
}

export default MenuSlider;
